<template>
  <div class="team">
    <v-alert
      v-if="!canCreateTeam"
      type="error"
    >
      You may not create a team.
    </v-alert>
    <v-form
      v-else
      ref="form"
      class="submit"
      @submit.prevent="submit"
    >
      <v-card>
        <v-card-title>
          Create a Team
        </v-card-title>
        <v-card-text>
          <Loading
            v-if="submitting"
            :loading="submitting"
            message="Creating Team"
          />
          <div v-else>
            <v-alert
              v-for="error in errorMessages"
              :key="error"
              type="error"
            >
              {{ error }}
            </v-alert>
            <div class="description">
              <p>
                Choose a unique name for your team. You'll be able to invite teammates and configure more details later.
              </p>
            </div>
            <v-text-field
              v-model="name"
              :disabled="submitting"
              :rules="teamNameRules"
              type="text"
              label="Team Name"
            />
          </div>
        </v-card-text>
        <v-card-actions
          v-if="!submitting"
          class="justify-center"
        >
          <v-btn
            class="primary"
            type="submit"
            :disabled="submitting"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import { allianceAPI } from '@/api'
import { mapState } from 'vuex'
import { parseResponseErrors } from '@/utils/errors'
import { teamNameRules } from '@/utils/rules'

export default {
  components: {
    Loading,
  },
  data: function() {
    return {
      name: '',
      success: null,
      submitting: false,
      errorMessages: null,
      teamNameRules: teamNameRules,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
    canCreateTeam() {
      return this.profile.can_create_teams
    },
  },
  created: function () {
  },
  methods: {
    submit () {
      this.errorMessages = null
      this.success = null
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      const data = new FormData()
      data.append('name', this.name)
      return allianceAPI.post(
        '/quest/team/',
        data,
      )
        .then((response) => {
          console.log('success!')
          console.log(response)
          this.success = true
          this.name = null
          this.$store.dispatch('teams/replace', {replacementObject: response.data})
          this.$router.push({ name: 'Team', params: { id: response.data.id }})
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
